<template>
  <div :class="{ 'has-logo': showLogo }" :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
        :text-color="sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
        :unique-opened="true"
        :active-text-color="theme"
        :collapse-transition="false"
        mode="vertical"
        class="mymenu"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
    <create-box ref="creator"/>
  </div>
</template>

<script setup>
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.module.scss'
import useAppStore from '@/store/modules/app'
import useSettingsStore from '@/store/modules/settings'
import usePermissionStore from '@/store/modules/permission'
import { useRouter,useRoute } from 'vue-router'
// import CreateBox from '@/components/CreateBox/index'
// import {getGroupList} from '@/api/project/project'

const show=ref(false)
const route = useRoute();
const appStore = useAppStore()
const settingsStore = useSettingsStore()
const permissionStore = usePermissionStore()
const router = useRouter()
const groupData=ref([])
const sidebarRouters =  computed(() => permissionStore.sidebarRouters);
const showLogo = computed(() => settingsStore.sidebarLogo);
const sideTheme = computed(() => settingsStore.sideTheme);
const theme = computed(() => settingsStore.theme);
const isCollapse = computed(() => !appStore.sidebar.opened);

const activeMenu = computed(() => {
  const { meta, path } = route;
  if (meta.activeMenu) {
    return meta.activeMenu;
  }
  return path;
})

const creator=ref(null)

function openBox(){
  if(show.value==true){
      show.value=false
  }else{
      show.value=true
  }
}

function goAdd(){
  router.push("/project")
}

function openCreate(){
  show.value=false
  creator.value.init()

}

function goTeam(id){
  router.push(`/project/project_list?permission=1&teamId=${id}`)
}

// function getList(){
//   getGroupList(1).then(res=>{
//      if(res.code==200){
//           groupData.value=res.list
//      }
//   })
// }
// getList()
</script>

<style lang="scss" scoped>

.mymenu{
  display: flex;
  flex-direction: column;
}
.createBtn {
  display: block;
  margin: 15px auto!important;
  width: 80%;
}
.hideSidebar .createBtn{
  span{
    display: none;
  }
}
.btnBox{
  >.el-button{
    display: block;
    margin-left: 0;
    width: 100%;
  }
}
.ellipse-text{
  overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.has-logo{
  :deep(.el-menu-item-group__title){
    display: none!important;
  }
}
.el-popper{
  :deep(.el-menu-item-group__title){
    display: none!important;
  }
}
</style>