<template>
  <router-view />
</template>

<script setup>
import useSettingsStore from '@/store/modules/settings'
import { handleThemeStyle } from '@/utils/theme'

onMounted(() => {
  nextTick(() => {
    // 初始化主题样式
    handleThemeStyle(useSettingsStore().theme)
  })
})
</script>
<style>
.pagination-container{
  background:transparent!important;
}
.el-pagination{
  justify-content: center;
  margin-top: 30px;
}
.demo-drawer__content {
    display: flex;
    flex-direction: column;
    height: 100%
}

.demo-drawer__content .demo-drawer-box{
    flex: 1
}

.demo-drawer__footer {
    display: flex
}

.demo-drawer__footer button {
    flex: 1
}

</style>